var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      class: {
        "updating-appointment": _vm.isUpdating,
        "saved-appointment": _vm.isSaved,
        "group-bg": _vm.isExpanded && _vm.appointment.group_id,
        selected: _vm.isSelected,
        "warning lighten-4": _vm.isClashExists,
      },
      attrs: {
        "data-id": _vm.appointment._id,
        "data-committed": _vm.appointment.commited,
      },
    },
    [
      _vm.isPharmacy
        ? _c(
            "td",
            { staticClass: "ml-2" },
            [
              _c("v-checkbox", {
                staticClass: "mt-0 ml-2",
                attrs: { value: _vm.isSelected, "hide-details": "" },
                on: { click: _vm.select },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "td",
        { staticClass: "toggle-group" },
        [
          _vm.appointment.group_first
            ? _c(
                "v-icon",
                {
                  attrs: { color: "#333", size: "20" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("expand", !_vm.isExpanded)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.isExpanded ? "mdi-folder-open" : "mdi-folder"
                      ) +
                      "\n        "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "td",
        { staticClass: "date", attrs: { "data-date": _vm.appointment.date } },
        [
          _c(
            "v-tooltip",
            {
              attrs: { top: "", "max-width": "300" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "span",
                        _vm._g(
                          _vm._b(
                            { class: _vm.appointment.commited && "committed" },
                            "span",
                            attrs,
                            false
                          ),
                          _vm.appointment.commited && on
                        ),
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.formatDate(_vm.appointment.date)) +
                              "\n                "
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [_vm._v(" "), _c("span", [_vm._v("Fest zugesagter Termin")])]
          ),
          _vm._v(" "),
          _vm.appointment.formatted_created_updated
            ? _c(
                "v-tooltip",
                {
                  attrs: { top: "", "max-width": "300" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      color: "secondary",
                                      dark: "",
                                      size: "20",
                                    },
                                  },
                                  "v-icon",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _vm._v(
                                  "\n                    mdi-calendar-month\n                "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2256720839
                  ),
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.appointment.formatted_created_updated)),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.appointment.nightshift
            ? _c(
                "v-tooltip",
                {
                  attrs: { top: "", "max-width": "300" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                _vm._b(
                                  { attrs: { color: "secondary", size: "20" } },
                                  "v-icon",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _vm._v(
                                  "\n                    mdi-cloud\n                "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3433028499
                  ),
                },
                [_vm._v(" "), _c("span", [_vm._v("Nachtdienst")])]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.appointment.self_assigned
            ? _c(
                "v-tooltip",
                {
                  attrs: { top: "", "max-width": "300" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                _vm._b(
                                  { attrs: { color: "secondary", size: "20" } },
                                  "v-icon",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _vm._v(
                                  "\n                    mdi-account-check\n                "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    519873416
                  ),
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "Der Freiberufler hat sich diesem Termin selbst direkt zugewiesen"
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.appointment.pricing_suggestions
            ? _c(
                "v-tooltip",
                {
                  attrs: { top: "", "max-width": "300" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "pricing-suggestions",
                                    attrs: {
                                      color: "secondary",
                                      size: "20",
                                      "data-title": `Am Termin gespeicherte Bepreisung: ${_vm.appointment.formatted_pricing_suggestions}`,
                                    },
                                  },
                                  "v-icon",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _vm._v(
                                  "\n                    mdi-currency-eur\n                "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1783385248
                  ),
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "Am Termin gespeicherte Bepreisung: " +
                        _vm._s(_vm.appointment.formatted_pricing_suggestions)
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            _vm._l(
              _vm.appointment.display_services_with_pricing,
              function (service) {
                return _c(
                  "v-chip",
                  {
                    staticClass: "mr-1 px-2",
                    attrs: {
                      color: _vm.getServiceColor(service.service),
                      dark: "",
                      "x-small": "",
                      label: "",
                    },
                  },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "", "max-width": "300" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "b",
                                    _vm._g(
                                      _vm._b({}, "b", attrs, false),
                                      service.pricing_text && on
                                    ),
                                    [_vm._v(_vm._s(service.title))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(service.pricing_text))]),
                      ]
                    ),
                  ],
                  1
                )
              }
            ),
            1
          ),
          _vm._v(" "),
          _vm.appointment.hba
            ? _c(
                "div",
                [
                  _c(
                    "v-chip",
                    {
                      staticClass: "mt-1 px-2 hba",
                      attrs: {
                        color: "purple",
                        tag: "b",
                        dark: "",
                        "x-small": "",
                        label: "",
                        outlined: "",
                      },
                    },
                    [_vm._v("\n                HBA erforderlich\n            ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.appointment.holidays, function (holiday) {
            return _c("b", { staticClass: "holiday" }, [
              _vm._v(_vm._s(holiday)),
            ])
          }),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("assign-to-admin", {
                key: _vm.appointment._id,
                attrs: {
                  appointment_id: _vm.appointment._id,
                  admin_id: _vm.appointment.admin_id,
                },
                on: { "selected-user": _vm.onPharmacistSelected },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("td", { staticClass: "group-enddate" }, [
        _vm.appointment.group_first
          ? _c("span", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.formatDate(_vm.appointment.group_enddate)) +
                  "\n        "
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "group-size" }, [
        _vm.appointment.group_first
          ? _c("span", [
              _vm._v(
                "\n            (" +
                  _vm._s(_vm.appointment.group_size) +
                  ")\n        "
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "pharmacy" }, [
        _c(
          "a",
          { attrs: { href: `/pharmacies/${_vm.appointment.pharmacy._id}` } },
          [_vm._v(_vm._s(_vm.appointment.pharmacy.name))]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: `/users/admin/?city=${_vm.appointment.pharmacy.zip}`,
              target: "_blank",
            },
          },
          [
            _c("v-icon", { attrs: { small: "", color: "blue-grey" } }, [
              _vm._v("\n                mdi-earth\n            "),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "text--secondary" }, [
          _vm._v(
            _vm._s(_vm.appointment.pharmacy.city) +
              _vm._s(", " + _vm.appointment.pharmacy.zip)
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "time" }, [_vm._v(_vm._s(_vm.appointment.time))]),
      _vm._v(" "),
      !_vm.appointment.group_id || _vm.appointment.group_first
        ? _c("td", [
            _c(
              "div",
              { staticClass: "d-flex align-center relative" },
              [
                _vm.isClashExists
                  ? _c(
                      "v-alert",
                      {
                        staticClass: "dates-clash",
                        attrs: {
                          type: "warning",
                          icon: "mdi-alert",
                          width: "300",
                          dismissible: "",
                          dense: "",
                        },
                      },
                      [
                        _vm._v(
                          "\n                Es gibt Terminkollisionen\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    attrs: {
                      "data-assignee-id": _vm.appointment?.pharmacist
                        ? _vm.appointment.pharmacist._id
                        : null,
                    },
                  },
                  [
                    _vm.wfState == "open" ||
                    _vm.wfState == "allocated" ||
                    _vm.wfState == "expired"
                      ? _c("PharmacistSearchDropdown", {
                          staticClass: "autocomplete",
                          attrs: {
                            services: _vm.appointment.available_services,
                            pharmacy: _vm.appointment.pharmacy,
                            value: _vm.selectedPharmacist,
                            isWarning: _vm.isClashExists,
                          },
                          on: { input: _vm.onPharmacistSelected },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.wfState === "allocated"
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "d-block mt-2 unassign",
                            attrs: {
                              disabled: !_vm.selectedPharmacist?._id,
                              rounded: "",
                              small: "",
                            },
                            on: { click: _vm.unassign },
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "mr-1", attrs: { left: "" } },
                              [_vm._v("mdi-cancel")]
                            ),
                            _vm._v(
                              "\n                    FB entfernen\n                "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.selectedPharmacist?._id &&
                (_vm.wfState == "open" || _vm.wfState == "allocated")
                  ? _c(
                      "a",
                      {
                        staticClass: "ml-3 pharmacist-link",
                        attrs: {
                          href: `/pharmacists/${_vm.selectedPharmacist._id}`,
                          target: "_blank",
                        },
                      },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "", "max-width": "300" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                size: "20",
                                                color: "blue",
                                              },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                            mdi-open-in-new\n                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              84894633
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.selectedPharmacist.firstname) +
                                  " " +
                                  _vm._s(_vm.selectedPharmacist.lastname)
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.wfState == "completed_no_apo_invoice" ||
                _vm.wfState == "apobilanz" ||
                _vm.wfState == "tempInvoice" ||
                _vm.wfState == "invoice" ||
                _vm.wfState == "paid"
                  ? _c(
                      "div",
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: `/pharmacists/${_vm.selectedPharmacist._id}`,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.appointment.pharmacist.firstname) +
                                " " +
                                _vm._s(_vm.appointment.pharmacist.lastname) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.appointment.user_state == "approved"
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  top: "",
                                  "max-width": "170",
                                  color: "#00b000",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "green darken-2",
                                                  },
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                            mdi-checkbox-marked-circle\n                        "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3295992486
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v("Alles war ok und wie besprochen."),
                                ]),
                              ]
                            )
                          : _vm.appointment.user_state == "awaiting"
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  top: "",
                                  "max-width": "250",
                                  color: "#fb8c00",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "orange darken-2",
                                                  },
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                            mdi-information\n                        "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  165877236
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c(
                                  "v-row",
                                  { staticClass: "v-application" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "pb-0 text-subtitle-1 font-weight-bold",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Es gab Änderungen:\n                            "
                                        ),
                                        _c("v-divider", {
                                          attrs: { dark: "", color: "#fff" },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      _vm._l(
                                        _vm.appointment.awaiting_comments,
                                        function (awaitingComment) {
                                          return _c("div", [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(awaitingComment.label)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(awaitingComment.comment)
                                              ),
                                            ]),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "v-tooltip",
                              {
                                attrs: { top: "", "max-width": "220" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                { attrs: { small: "" } },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                            mdi-help-circle\n                        "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1885978917
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "Termin muss durch FA noch bestätigt werden."
                                  ),
                                ]),
                              ]
                            ),
                        _vm._v(" "),
                        _vm.appointment.pharmacist.asset
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: { top: "", "max-width": "220" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: `/assets/${_vm.appointment.pharmacist.asset}`,
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { small: "" } },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                                mdi-school\n                            "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1558949383
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "\n                        Aprobationsurkunde " +
                                      _vm._s(
                                        _vm.appointment.pharmacist.firstname
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.appointment.pharmacist.lastname
                                      ) +
                                      "\n                    "
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.wfState == "inquired" || _vm.wfState == "open"
                  ? _c("AvailablePharmacistsDialogButton", {
                      attrs: {
                        wfState: _vm.wfState,
                        pharmacy: _vm.appointment.pharmacy,
                        appointment: _vm.appointment,
                        services: _vm.appointment.available_services,
                        date: _vm.appointment.date,
                      },
                      on: { "selected-user": _vm.onPharmacistSelected },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.appointment.group_id && !_vm.appointment.group_first
        ? _c("td", { staticClass: "text--secondary group-pharmacist" }, [
            _vm._v(
              "\n        Name des genauen Vertreters steht in Kürze fest\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "td",
        { staticClass: "travelcosts" },
        [
          _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "max-width": "250",
                color:
                  _vm.maxCostsInfo && !_vm.appointment.group_id
                    ? "#fb8c00"
                    : "#ff5252",
                disabled:
                  _vm.appointment.travelcosts <= _vm.maxTravelCosts &&
                  (_vm.appointment.group_id || !_vm.maxCostsInfo),
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _vm.wfState === "open" ||
                      _vm.wfState === "allocated" ||
                      _vm.wfState === "completed_no_apo_invoice" ||
                      _vm.wfState === "expired"
                        ? _c(
                            "v-text-field",
                            _vm._g(
                              _vm._b(
                                {
                                  class:
                                    _vm.maxCostsInfo &&
                                    !_vm.appointment.group_id &&
                                    "warning-input",
                                  staticStyle: { "min-width": "70px" },
                                  attrs: {
                                    error:
                                      _vm.appointment.travelcosts >
                                        _vm.maxTravelCosts &&
                                      !_vm.appointment.group_id,
                                    type: "number",
                                    min: "0",
                                    "hide-details": "",
                                    outlined: "",
                                    dense: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      _vm.isUpdating = true
                                      _vm.isSaved = false
                                    },
                                  },
                                  model: {
                                    value: _vm.travelCosts,
                                    callback: function ($$v) {
                                      _vm.travelCosts = $$v
                                    },
                                    expression: "travelCosts",
                                  },
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              on
                            )
                          )
                        : _c("span", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$n(
                                    _vm.appointment.travelcosts,
                                    "currency",
                                    "de-DE"
                                  )
                                ) +
                                "\n                "
                            ),
                          ]),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _vm.maxCostsInfo && !_vm.appointment.group_id
                ? _c("span", [_vm._v(_vm._s(_vm.maxCostsInfo))])
                : _c("span", [
                    _vm._v(
                      "Die Reisekosten sollen bei Einzelterminen nicht höher als " +
                        _vm._s(
                          _vm.$n(_vm.maxTravelCosts, "currency", "de-DE")
                        ) +
                        " sein."
                    ),
                  ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "td",
        { staticClass: "pricing" },
        _vm._l(_vm.appointment.price_details, function (price, i) {
          return _c(
            "div",
            { attrs: { "data-service": price.service } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: {
                    right: "",
                    "nudge-right": "-30",
                    "max-width": "220",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _vm.appointment.billing == "hours"
                              ? _c(
                                  "section",
                                  _vm._g(
                                    _vm._b(
                                      { class: i == 1 && "mt-2" },
                                      "section",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("div", [
                                      _c("p", [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.$n(
                                                price.total_apo,
                                                "currency",
                                                "de-DE"
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "\n                                (" +
                                            _vm._s(_vm.appointment.quantity) +
                                            " St. x "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "hourly-apo-rate" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$n(
                                                  price.apo_rate,
                                                  "currency",
                                                  "de-DE"
                                                )
                                              ) + ")"
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text--secondary total-fb-costs",
                                        class: price.is_fb_pricier_than_apo
                                          ? "negative-balance"
                                          : "",
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$n(
                                                price.total_fb,
                                                "currency",
                                                "de-DE"
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("p", [
                                          _vm._v(
                                            "\n                                (" +
                                              _vm._s(_vm.appointment.quantity) +
                                              " St. x " +
                                              _vm._s(
                                                _vm.$n(
                                                  price.fb_rate,
                                                  "currency",
                                                  "de-DE"
                                                )
                                              ) +
                                              ") (FB)\n                            "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "section",
                                  _vm._g(
                                    _vm._b({}, "section", attrs, false),
                                    on
                                  ),
                                  [
                                    _c("div", [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$n(
                                              price.total_apo,
                                              "currency",
                                              "de-DE"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text--secondary total-fb-costs",
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$n(
                                                price.total_fb,
                                                "currency",
                                                "de-DE"
                                              )
                                            ) + " (FB)"
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(price.service_name) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  price.applied_discount &&
                  price.applied_discount.discountAmount
                    ? _c("span", [
                        _vm._v(
                          "\n                    , Rabattname: " +
                            _vm._s(price.applied_discount.discountName) +
                            ", Rabattwert:  - " +
                            _vm._s(
                              _vm.$n(
                                price.applied_discount.discountAmount,
                                "currency",
                                "de-DE"
                              )
                            ) +
                            "\n                "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _vm.appointment.other_costs
                ? _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm.$n(_vm.appointment.other_costs, "currency", "de-DE")
                      )
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("td", { staticClass: "action-buttons" }, [
        _c(
          "div",
          { staticClass: "mb-2 text-center" },
          [
            _vm.appointment.comment
              ? _c(
                  "v-tooltip",
                  {
                    attrs: { top: "", "max-width": "300" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        color: "secondary",
                                        dark: "",
                                        size: "20",
                                      },
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm._v(
                                    "\n                        mdi-comment\n                    "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      969009851
                    ),
                  },
                  [
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "v-application" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "pb-0 text-subtitle-1 font-weight-bold",
                            attrs: { cols: "12" },
                          },
                          [
                            _vm._v(
                              "\n                        Kommentar\n                        "
                            ),
                            _c("v-divider", {
                              attrs: { dark: "", color: "#fff" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-col", { attrs: { cols: "12" } }, [
                          _c("span", [_vm._v(_vm._s(_vm.appointment.comment))]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.appointment.internal_comment
              ? _c(
                  "v-tooltip",
                  {
                    attrs: { top: "", "max-width": "300" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        color: "secondary",
                                        dark: "",
                                        size: "20",
                                      },
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm._v(
                                    "\n                        mdi-comment-text-multiple\n                    "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1948858838
                    ),
                  },
                  [
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "v-application" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "pb-0 text-subtitle-1 font-weight-bold",
                            attrs: { cols: "12" },
                          },
                          [
                            _vm._v(
                              "\n                        Interner Kommentar\n                        "
                            ),
                            _c("v-divider", {
                              attrs: { dark: "", color: "#fff" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-col", { attrs: { cols: "12" } }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.appointment.internal_comment)),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.appointment.comment_to_fb
              ? _c(
                  "v-tooltip",
                  {
                    attrs: { top: "", "max-width": "300" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        color: "secondary",
                                        dark: "",
                                        size: "20",
                                      },
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm._v(
                                    "\n                        mdi-comment-account-outline\n                    "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1215091258
                    ),
                  },
                  [
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "v-application" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "pb-0 text-subtitle-1 font-weight-bold",
                            attrs: { cols: "12" },
                          },
                          [
                            _vm._v(
                              "\n                        Kommentar für Freiberufler\n                        "
                            ),
                            _c("v-divider", {
                              attrs: { dark: "", color: "#fff" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-col", { attrs: { cols: "12" } }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.appointment.comment_to_fb)),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.isUpdating
          ? _c(
              "div",
              { staticClass: "justify-center flex-wrap" },
              [
                _vm.wfState !== "inquired" && _vm.wfState !== "deleted"
                  ? _c("ProposePharmacistDialog", {
                      attrs: {
                        pharmacy: _vm.appointment.pharmacy,
                        appointmentid: _vm.appointment._id,
                        disabled: _vm.appointment.hidden,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.wfState !== "deleted"
                  ? _c("MarkAsHiddenBtn", {
                      attrs: {
                        disabled:
                          _vm.wfState != "open" && _vm.wfState != "inquired",
                        is_appointment_hidden: _vm.appointment.hidden,
                        appointmentid: _vm.appointment._id,
                      },
                      on: {
                        update: (appointments) =>
                          this.$emit("update-appointments", appointments),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.wfState !== "deleted"
                  ? _c("ToggleSelfAssignBtn", {
                      attrs: {
                        disabled:
                          _vm.wfState != "open" && _vm.wfState != "inquired",
                        isSelfAssignDisabled:
                          _vm.appointment.disable_self_assign,
                        appointmentid: _vm.appointment._id,
                      },
                      on: {
                        update: (appointments) =>
                          this.$emit("update-appointments", appointments),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.wfState !== "deleted"
                  ? _c("ToggleDirectProposalBtn", {
                      attrs: {
                        disabled:
                          _vm.wfState != "open" && _vm.wfState != "inquired",
                        isDirectProposalAllowed:
                          _vm.appointment.allow_direct_proposal,
                        appointmentid: _vm.appointment._id,
                      },
                      on: {
                        update: (appointments) =>
                          this.$emit("update-appointments", appointments),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.wfState !== "deleted"
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "tickets",
                        attrs: {
                          href: `/pharmacies/${_vm.appointment.pharmacy._id}/${_vm.appointment._id}/tickets`,
                          "min-width": "30",
                          small: "",
                        },
                      },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("mdi-list-box-outline"),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.wfState !== "deleted"
                  ? _c("PriceSuggestion", {
                      attrs: {
                        pharmacyId: _vm.appointment.pharmacy._id,
                        appointmentId: _vm.appointment._id,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "edit",
                    attrs: {
                      href: `/pharmacies/${_vm.appointment.pharmacy._id}/${_vm.appointment._id}/edit`,
                      color: "cyan lighten-1",
                      "min-width": "30",
                      dark: "",
                      small: "",
                    },
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v("mdi-pencil"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.wfState == "open" || _vm.wfState == "inquired"
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "delete-btn",
                        attrs: {
                          color: "error",
                          "min-width": "30",
                          dark: "",
                          small: "",
                        },
                        on: {
                          click: function ($event) {
                            _vm.isDeleteAppointmentDialogOpen = true
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("mdi-trash-can-outline"),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.appointment.commited &&
                (_vm.appointment.group_first || !_vm.appointment.group_id) &&
                _vm.wfState == "open"
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "commit-with-email",
                        attrs: {
                          disabled: _vm.isLoading,
                          color: "primary",
                          small: "",
                          outlined: "",
                        },
                        on: { click: _vm.commit },
                      },
                      [
                        _vm._v(
                          "\n                Zusage an Apotheke\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.wfState == "completed_no_apo_invoice" &&
                (_vm.appointment.user_state === "" ||
                  _vm.appointment.user_state === null)
                  ? _c("ApprovalReminderDialogButton", {
                      attrs: { pharmacist_id: _vm.appointment.pharmacist._id },
                      model: {
                        value: _vm.isApprovalReminderDialogOpen,
                        callback: function ($$v) {
                          _vm.isApprovalReminderDialogOpen = $$v
                        },
                        expression: "isApprovalReminderDialogOpen",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.wfState !== "deleted"
                  ? _c("AutoProposalBtn", {
                      attrs: {
                        disabled:
                          _vm.wfState != "open" ||
                          _vm.appointment.hidden ||
                          _vm.appointment.allow_direct_proposal,
                        appointment_id: _vm.appointment._id,
                        is_auto_proposed: _vm.appointment.is_auto_proposed,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                (_vm.wfState == "open" ||
                  _vm.wfState == "allocated" ||
                  _vm.wfState == "deleted") &&
                (!_vm.appointment.group_id || _vm.appointment.group_first)
                  ? _c("PharmacistsRequests", {
                      attrs: {
                        appointment_data: _vm.appointment,
                        pharmacy_data: _vm.appointment.pharmacy,
                        pharmacist_data: _vm.appointment.pharmacist,
                        known_pharmacists_data:
                          _vm.appointment.known_pharmacists,
                        num_of_requests:
                          _vm.appointment.pharmacist_requests_count,
                      },
                      on: { "selected-user": _vm.onPharmacistSelected },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "text-center" },
              [
                _vm.wfState == "open" ||
                _vm.wfState == "allocated" ||
                _vm.wfState === "completed_no_apo_invoice" ||
                _vm.wfState === "expired"
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { top: "", "max-width": "400" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "save",
                                          attrs: {
                                            color: "cyan lighten-1",
                                            "min-width": "70",
                                            small: "",
                                            dark: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.checkCommittedState(
                                                "update"
                                              )
                                            },
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n                        Speichern\n                    "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          619566896
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "Speichern, es werden keine E-Mails versendet"
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                (!_vm.appointment.group_id || _vm.appointment.group_first) &&
                (_vm.wfState == "open" || _vm.wfState == "expired") &&
                _vm.selectedPharmacist?._id
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { "max-width": "400", top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mt-2 save-with-email",
                                          attrs: {
                                            color: "cyan lighten-1",
                                            "min-width": "70",
                                            small: "",
                                            dark: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.checkCommittedState(
                                                "update_ext/false"
                                              )
                                            },
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n                        Speichern + E-Mails senden\n                    "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2514558354
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "Speichern und E-Mails senden, aber ohne Unterkunftsemail"
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.appointment.group_first &&
                _vm.appointment.is_part_of_a_blockbuster &&
                _vm.selectedPharmacist?._id
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { top: "", "max-width": "400" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass:
                                            "mt-2 save-with-accommodation",
                                          attrs: {
                                            color: "cyan lighten-1",
                                            "min-width": "70",
                                            small: "",
                                            dark: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.checkCommittedState(
                                                "update_ext/true"
                                              )
                                            },
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n                        S + E + Unterkunft\n                    "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1564938514
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "Speichern und E-Mails inklusive Unterkunftsemail senden"
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
      ]),
      _vm._v(" "),
      _c("DeleteAppointmentDialog", {
        on: {
          "confirm-deletion": function ($event) {
            return _vm.deleteAppointment(
              _vm.appointment.pharmacy._id,
              _vm.appointment._id
            )
          },
        },
        model: {
          value: _vm.isDeleteAppointmentDialogOpen,
          callback: function ($$v) {
            _vm.isDeleteAppointmentDialogOpen = $$v
          },
          expression: "isDeleteAppointmentDialogOpen",
        },
      }),
      _vm._v(" "),
      _c("ConfirmationDialog", {
        attrs: {
          message:
            "Achtung: Der Termin wurde noch nicht fest zugesagt! Wollen Sie ihn wirklich speichern?",
        },
        on: {
          save: function ($event) {
            return _vm.saveAppointment()
          },
        },
        model: {
          value: _vm.isCommittedDialogOpen,
          callback: function ($$v) {
            _vm.isCommittedDialogOpen = $$v
          },
          expression: "isCommittedDialogOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }