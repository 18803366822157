'use strict'
import 'es6-promise/auto'

import "@fontsource/roboto/latin.css"

import Vue from 'vue'
import i18n from './i18n'
import * as uiv from 'uiv';

import axios from 'axios'
import VueAxios from 'vue-axios'
import vuetify from './vuetify'

import {store} from './store'

import VCalendar from 'v-calendar';
//import Spinner from 'vue-simple-spinner'
import ClipboardList from './components/ClipboardList.vue'
import AppointmentClipboardList from './components/AppointmentClipboardList.vue'
import StickyNote from './components/StickyNote.vue'
import AppointmentsControlButtons from './components/pharmacist/AppointmentsControlButtons.vue'
import ErpExpertiseInput from './components/pharmacist/ErpExpertiseInput.vue'
import RowSelectCheckbox from './components/RowSelectCheckbox.vue'
import ContractorDatesCalendar from './components/pharmacist/ContractorDatesCalendar.vue'
import ContractorBlacklist from './components/pharmacy/ContractorBlacklist.vue'

import ContractorsWithMailSubscription from './components/statistics/ContractorsWithMailSubscription.vue'
import AppUsageStatistics from './components/statistics/AppUsageStatistics.vue'
import WrongMarginAppointments from './components/statistics/WrongMarginAppointments.vue'
import InquiredAppointmentsPerDayStatistics from './components/statistics/InquiredAppointmentsPerDay.vue'

import HolidaysView from './components/holidays/View.vue'
import HolidayRow from './components/holidays/Row.vue'
import ProposePharmacistDialog from './components/appointments/ProposePharmacistDialog.vue'
import AvailablePharmacistsDialogButton from  './components/appointments/AvailablePharmacistsDialogButton.vue'
import PriceSuggestion from './components/appointments/PriceSuggestion.vue'
import BatchUpdatePrices from './components/appointments/BatchUpdatePrices.vue'
import CreateAppointments from './components/appointments/CreateAppointments.vue'
import PharmaciesOverview from './components/pharmacy/PharmaciesOverview.vue'
import PharmaciesOverviewTable from './components/pharmacy/PharmaciesOverviewTable.vue'
import PharmacistsOverview from './components/pharmacist/PharmacistsOverview.vue'
import AppointmentsOverview from './components/appointments/AppointmentsOverview.vue'
import Alert from './components/Alert.vue'
import MarkAsHiddenBtn from './components/appointments/MarkAsHiddenBtn.vue'
import ToggleSelfAssignBtn from './components/appointments/ToggleSelfAssignBtn.vue'
import PricingDiscounts from './components/pharmacy/PricingDiscounts.vue'
import LinkedDiscounts from './components/pharmacy/discounts/LinkedDiscounts.vue'
import PharmacistsRequests from './components/appointments/PharmacistsRequests.vue';
import AssignToAdmin from './components/appointments/AssignToAdmin.vue'
import ContractorFavorite from './components/pharmacy/ContractorFavorite.vue'
import AddPharmacySource from './components/pharmacy/AddPharmacySource.vue'
import ToggleDirectProposalBtn from './components/appointments/ToggleDirectProposalBtn.vue'
import PharmacistThreadsList from './components/pharmacist/threads/PharmacistThreadsList.vue'
import ApprovalReminderDialogButton from './components/appointments/ApprovalReminderDialogButton.vue'

import TicketOverview from './components/tickets/TicketOverview.vue'
import JobsOverview from './components/jobs/JobsOverview.vue'
import AutoProposalBtn from './components/appointments/AutoProposalBtn.vue'


Vue.use(VueAxios, axios)

Vue.use(uiv);

Vue.use(VCalendar, { componentPrefix: 'vc' });
// Vue.use(VueI18n);

//Vue.component('spinner', Spinner)
Vue.component('clipboard-list', ClipboardList)
Vue.component('appointment-clipboard-list', AppointmentClipboardList)
Vue.component('sticky-note', StickyNote)
Vue.component('appointments-control-buttons', AppointmentsControlButtons)
Vue.component('erp-expertise-input', ErpExpertiseInput)
Vue.component('row-select-checkbox', RowSelectCheckbox)
Vue.component('contractor-dates-calendar', ContractorDatesCalendar)
Vue.component('contractor-blacklist', ContractorBlacklist)
Vue.component('contractor-favorite', ContractorFavorite)

Vue.component('contractors-with-mail-subscription', ContractorsWithMailSubscription)
Vue.component('app-usage-statistics', AppUsageStatistics)
Vue.component('wrong-margin-appointments', WrongMarginAppointments)
Vue.component('inquired-appointments-per-day-statistics', InquiredAppointmentsPerDayStatistics)

Vue.component('holidays-view', HolidaysView)
Vue.component('holiday-row', HolidayRow)
Vue.component('price-suggestion', PriceSuggestion)
Vue.component('batch-update-prices', BatchUpdatePrices)
Vue.component('propose-pharmacist-dialog', ProposePharmacistDialog)
Vue.component('available-pharmacist-dialog-button', AvailablePharmacistsDialogButton)
Vue.component('create-appointments', CreateAppointments)
Vue.component('pharmacies-overview', PharmaciesOverview)
Vue.component('pharmacies-overview-table', PharmaciesOverviewTable)
Vue.component('pharmacists-overview', PharmacistsOverview)
Vue.component('appointments-overview', AppointmentsOverview)
Vue.component('alert', Alert)
Vue.component('mark-appointment-as-hidden-btn', MarkAsHiddenBtn)
Vue.component('toggle-self-assign-btn', ToggleSelfAssignBtn)
Vue.component('pricing-discounts', PricingDiscounts)
Vue.component('linked-discounts', LinkedDiscounts)
Vue.component('pharmacists-requests', PharmacistsRequests)
Vue.component('assign-to-admin', AssignToAdmin)
Vue.component('add-pharmacy-source', AddPharmacySource)
Vue.component('toggle-direct-proposal-btn', ToggleDirectProposalBtn)
Vue.component('pharmacist-threads-list', PharmacistThreadsList)
Vue.component('ticket-overview', TicketOverview)
Vue.component('jobs-overview', JobsOverview)
Vue.component('approval-reminder-dialog-button', ApprovalReminderDialogButton)
Vue.component('auto-proposal-btn', AutoProposalBtn)



new Vue({
    el: '#content',
    store,
    vuetify,
    i18n,
    ready: function() {
        i18n.locale = 'de';
    },
})
